<template>
   <div class="">
      <section class="video_streaming">
         <div class="container extrawitdh">
            <div class="row">
               <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="video_streaming_content">
                     <h1>A video streaming<br>
                        platform just for<br>
                        kids!
                     </h1>
                     <p>A video streaming platform to kids to learn the science, sports, <br>
                        mathematics and other learning activities without ads. It will<br> 
                        help them to learn without any disturbing, fees and 100% kids <br>
                        learning content. Lorem Ipsum is simply dummy text of the <br>
                        printing.
                     </p>
                  </div>
               </div>
               <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="video_streaming_image">
                     <img src="../assets/images/video_streaming.jpg" alt="video streaming" />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="why_exsports_space">
         <div class="container extrawitdh">
            <div class="why_exsports_space_title">
               <h2>Why Exsports.space?</h2>
               <p>We value the importance of your kids being able to learn in an environment that allows them to discover and learn.<br>
                  Our platform provides you:
               </p>
            </div>
            <div class="why_exsports_space_list">
               <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                     <div class="card">
                        <div class="card_image">
                           <img src="../assets/images/no_ads.png" alt="Card image">
                        </div>
                        <div class="card-body">
                           <h4 class="card-title">No Ads</h4>
                           <p class="card-text">Lorem Ipsum has been the industry's<br>
                              standard dummy text ever since<br>
                              the 1500s, 
                           </p>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                     <div class="card">
                        <div class="card_image">
                           <img src="../assets/images/kids_content.png" alt="Card image">
                        </div>
                        <div class="card-body">
                           <h4 class="card-title">Kids Content</h4>
                           <p class="card-text">Lorem Ipsum has been the industry's<br>
                              standard dummy text ever since<br>
                              the 1500s, 
                           </p>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                     <div class="card">
                        <div class="card_image">
                           <img src="../assets/images/no_fees.png" alt="Card image">
                        </div>
                        <div class="card-body">
                           <h4 class="card-title">No Fees</h4>
                           <p class="card-text">Lorem Ipsum has been the industry's<br>
                              standard dummy text ever since<br>
                              the 1500s, 
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="want_to_learn">
         <div class="container extrawitdh">
            <div class="row">
               <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="want_to_learn_title">
                     <h2>Want to learn?</h2>
                     <p>Explore our platform to learn something new everyday.<br> 
                        A video streaming platform to kids to learn the science, sports,<br> 
                        mathematics and other learning activities without ads. It will <br>
                        help them to learn without any disturbing, fees and 100% kids <br>
                        learning content. Lorem Ipsum is simply dummy text of the <br>
                        printing.
                     </p>
                  </div>
               </div>
               <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="want_to_learn_image">
                     <img src="../assets/images/want_to_learn1.jpg" alt="video streaming" />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="help_kids_learn">
         <div class="container extrawitdh">
            <div class="row">
               <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="help_kids_learn_image">
                     <img src="../assets/images/help_kids_learn.jpg" alt="video streaming" />
                  </div>
               </div>
               <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="help_kids_learn_content">
                     <h2>Help kids learn!</h2>
                     <p>Create a page to stream videos for kids to learn everyday<br>
                        Upload videos of Science, Mathematics, Sports and other<br>
                        learning activities without ads and content should be 100%<br>
                        kids.Lorem Ipsum is simply dummy text of the printing & <br>
                        typesetting industry. Lorem Ipsum has been the industry's<br>
                        standard dummy text ever since the 1500s,
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
   export default {
     name: "About",
   
    data() {
    return {
      value: ""
    };
  },
     methods: {
    
     },
   };
</script>
<style></style>