<template>
   <div class="">
      <section class="reach_us">
         <div class="container">
            <div class="contact_us_title">
               <h1>Contact us</h1>
               <p>Drop us a message and we'll get back to you.</p>
            </div>
            <div class="reachus-common">
               <div class="row">
                  <div class="col-lg-7 col-md-7 col-sm-12 col-12 padding0">
                     <div class="reachus_form">
                       <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                  <form @submit.prevent="handleSubmit(Contact)"  @reset.prevent="reset" >

                          <div class="row">
                            <div class="col-md-6"><validation-provider
                            v-slot="{ errors }"
                            name="Name"
                            rules="required|max:10"
                          >
                            <v-text-field
                              v-model="name"
                             
                              :error-messages="errors"
                              label="Name"
                            ></v-text-field>
                          </validation-provider></div>
                            <div class="col-md-6">
                               <validation-provider
                            v-slot="{ errors }"
                            name="email"
                            rules="required|email">
                            <v-text-field
                              v-model="email"
                              :error-messages="errors"
                              label="E-mail"
                              
                            ></v-text-field>
                          </validation-provider>
                          </div>
                          </div>
                         <div class="row">
                           <div class="col-md-6">
                              <validation-provider
                            v-slot="{ errors }"
                            name="phoneNumber"
                            :rules="{  required: true  }"
                          >
                            <v-text-field
                              v-model="phoneNumber"
                             
                              :error-messages="errors"
                              label="Phone Number"
                              
                            ></v-text-field>
                          </validation-provider>
                           </div>
                         </div>
                        <div class="row">
                          <v-col
                                cols="12"
                                md="12"
                              >
                               <validation-provider
                            v-slot="{ errors }"
                            name="message"
                            :rules="{
                              required: true
                            }">
                                <v-textarea
                                 v-model="message"
                             
                              :error-messages="errors"
                              label="Message"
                              
                                  hint="Hint text"
                                ></v-textarea>
                         </validation-provider>

                              </v-col>
                        </div>

                         <div class="row">
                           <div class="col-md-6">
                           <div class="gogolecaptch">
                              <vue-recaptcha
                                ref="recaptcha"
                                @verify="onCaptchaVerified"
                                @expired="onCaptchaExpired"
                                size="compact "
                                :sitekey="siteKey"
                                :loadRecaptchaScript="true"
                            
                                recaptchaHost="www.recaptcha.net">
                              </vue-recaptcha>
                              <!-- <vue-recaptcha ref="recaptcha" sitekey="6LcTpsEdAAAAABvjuiv0bSxZUCLBmWVZyyv9usRe" /> -->
                       
                           </div>
       
                           </div>
                           <div class="col-md-6">
                           <v-btn class="orange--text"
                            type="submit"
                           >
                            submit
                          </v-btn>

                           </div>
                         </div>
                        </form>
                </ValidationObserver>
                     </div>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-12 col-12 padding0">
                     <div class="reachus_bg">
                        <div class="reachus_head">
                           <h2>Reach us!</h2>
                        </div>
                        <hr>
                        <div class="reachus_address">
                           <div class="row">
                              <div class="col-lg-3 col-md-3 col-sm-3 col-3"><img src="../assets/images/location.png" alt="location" /></div>
                              <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                                 <a href="#">
                                    <p>#345, Kariyanpalya, Lingarajapuram, 
                                      Bangalore – 560084.
                                    </p>
                                 </a>
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-lg-3 col-md-3 col-sm-3 col-3"><img src="../assets/images/mail.png" alt="email" /></div>
                              <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                              <a href="mailto:info@pupiltube.space">
                              <p>info@pupiltube.space</p></a>
                              </div>
                           </div>
                           <div class="reachus_phone">
                              <div class="row">
                                 <div class="col-lg-3 col-md-3 col-sm-3 col-3"><img src="../assets/images/call.png" alt="phone" /></div>
                                 <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                                    <a href="#">
                                       <p>+91 080 41104089 / 25470101</p>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
        let siteKey = "6LeHMokdAAAAAI2RqzgoqXkHmPe6rrmqNnMXIZzH"

  import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
  import Helpsupport from '@/services/VideoService'
  import { VueRecaptcha } from 'vue-recaptcha';

  setInteractionMode('eager')
  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })

  export default {
     name: "Contact",
    components: {
      ValidationProvider,
      ValidationObserver,
      VueRecaptcha
    },
    data: () => ({
      name: '',
      phoneNumber: '',
      email: '',
      message: '',
      siteKey: siteKey

    }),
    methods: {
      async Contact () {

     await Helpsupport.contactus({
          name : this.name,
          mobile : this.phoneNumber,
          email : this.email,
          message : this.message,

          }).then((response) => {
            console.log(response.status)
                     if (response.status === 200) {
                      
              this.$toasted.show("Your post has been submitted succesfully", {
                theme: "bubble",
                position: "bottom-right",
                type: "success",
                duration: 5000
              });
            this.reset();
                     }
                })
                 .catch(errors => {
                this.$toasted.show(errors, {
                  theme: "bubble",
                  position: "bottom-right",
                  errors: "errors",
                  singleton: true,
                  duration: 5000
                });
              });  

      },
       onEvent() {
        // when you need a reCAPTCHA challenge
        this.$refs.recaptcha.execute();
      },
      reset () {
        this.name = ''
        this.phoneNumber = ''
        this.email = ''
        this.message = ''

        this.$refs.form.reset()
      },
      onCaptchaVerified: function(recaptchaToken) {
         console.log("recaptchaToken", recaptchaToken)
                    this.captchaAttempted = true;
                    this.$emit('captcha-attempted', recaptchaToken);
      },
      onCaptchaExpired: function() {
          this.$refs.recaptcha.reset();
          this.$emit('captcha-expired');
      },
      initVerifiy: function(){
          alert('running verification...')
          this.$refs.recaptcha.execute();
      }
    },
     mounted() {
                let recaptchaScript = document.createElement('script')
                recaptchaScript.setAttribute('src', 'https://www.recaptcha.net/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
                document.head.appendChild(recaptchaScript);

            },

  }
</script>
<style></style>