<template>
   <div class="">
      <div class="after_lofin_section">
        <section class="edit_profile">
   <div class="container-fluid">
      <div class="exsports_main_login">
         <div class="exsports_edit_profile_title">
            
               <div class="login_top_sec">
           <router-link to="/">   <img src="../../assets/images/login-logo.svg" alt="Exsport"/></router-link>
             <h2>Change password</h2>
            <v-card-subtitle>Please change your password</v-card-subtitle>
            </div>
         </div>
         <div class="login_form">
            <ValidationObserver
              ref="passwordForm"
              v-slot="{ handleSubmit, reset }"
            >
            <form   @submit.prevent="handleSubmit(submitPassword)"
                @reset.prevent="reset"> 
                  <ValidationProvider
                  v-slot="{ errors }"
                  name="Current password"
                  rules="required|min:5"
                >
          <v-text-field
             v-model="formData.currentPassword"
                    :append-icon="
                      showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :error-messages="errors"
                    :type="showCurrentPassword ? 'text' : 'password'"
                    name="Current password"
                    label="Current Password"
                  
                    hint="At least 5 characters"
                    class="input-group--focused"
                    @click:append="showCurrentPassword = !showCurrentPassword"
                    outlined
                    dense
          ></v-text-field>
            </ValidationProvider >
              <ValidationProvider
                  v-slot="{ errors }"
                  name="New password"
                  rules="required|min:5"
                >
        <v-text-field
            v-model="formData.newPassword"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :error-messages="errors"
                    :type="showNewPassword ? 'text' : 'password'"
                    name="New password"
                    label="New Password"
                   
                    hint="At least 5 characters"
                    class="input-group--focused"
                    @click:append="showNewPassword = !showNewPassword"
            outlined
            dense
          ></v-text-field>
          </ValidationProvider>
           <ValidationProvider
                  v-slot="{ errors }"
                  name="Confirm password"
                  rules="required|min:5"
                >
       <v-text-field
            v-model="formData.confirmPassword"
            :append-icon="showconfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :error-messages="errors"
                    :type="showconfirmPassword ? 'text' : 'password'"
                    name="Confirm password"
                    label="Confirm Password"
                   
                    hint="At least 5 characters"
                    class="input-group--focused"
                    @click:append="showconfirmPassword = !showconfirmPassword"
            outlined
            dense
          ></v-text-field>
 </ValidationProvider>
               <div class="login_buttons">
                  <div class="row">
                     <div class="col-lg-6 col-md-6 col-6">
                     <v-btn  @click ="resetpassword"
                      depressed class="oragne_cancel">
                       Cancel
                       </v-btn>
 
                     </div>
                     <div class="col-lg-6 col-md-6 col-6">
                           <v-btn text @click ="submitPassword"
                         depressed
                        class="oragne_buttons">
                         Save
                         </v-btn>
                     </div>
                  </div>
               </div>
            </form>
             </ValidationObserver>
         </div>
      </div>
   </div>
</section>
      </div>
   </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

  export default {
       name: 'Change Password',

    data () {
      return {
        show1: false,
        show2: true,
        show3: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showconfirmPassword: false,
         formData: {
        currentPassword: '',
        newPassword: '',
       confirmPassword: ''

      },

        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
      }
    },
 methods: {
        async submitPassword() {
      if (this.loading.password) return

      this.loading.password = true
      const user = await AuthenticationService.updatePassword({
        currentPassword: this.formData.currentPassword,
        newPassword: this.formData.newPassword,
        confirmPassword: this.formData.confirmPassword

      })
        .catch((err) => {
          this.loading.password = false
          const errors = err.response.data.error

          this.$refs.passwordForm.setErrors({
            'Current password': errors.find((error) => {
              return error.field === 'currentPassword'
            })
              ? ['Current password is incorrect']
              : null,
            'New password': errors.find((error) => {
              return error.field === 'newPassword'
            })
              ? errors.find((error) => {
                  return error.field === 'newPassword'
                }).message
              : null,
               'Confirm password': errors.find((error) => {
              return error.field === 'confirmPassword'
            })
              ? errors.find((error) => {
                  return error.field === 'confirmPassword'
                }).message
              : null
          })
        })
        .finally(() => (this.loading.password = false))
      if (!user) return

      this.formData.currentPassword = ''
      this.formData.confirmPassword = ''

      this.formData.newPassword = ''

      this.$store.dispatch('signOut')
      this.$toasted.show("password changed succesfully", {
                theme: "bubble",
                position: "bottom-right",
                type: "success",
                duration: 5000
              });
      this.$router.push('/signin')
    },
        async resetpassword() {
       this.formData.currentPassword = '',
      this.formData.confirmPassword = '',

      this.formData.newPassword = ''
        },
     loading: {
        password: false
      },
  }
  }
</script>

<style>
.v-application--is-ltr .v-text-field--outlined fieldset {
    background: #ffffff; border:1px solid #ebebeb
}

</style>