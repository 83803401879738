<template>
   <div class="">
      <div class="after_lofin_section">
        <section class="edit_profile">
   <div class="container-fluid">
      <div class="exsports_main_login">
         <div class="exsports_edit_profile_title">
            <h1>Edit profile</h1>
         </div>
         <div class="exsports_edit_profile_logo">
          <v-uploader @done="uploadDone"></v-uploader>
         </div>
         <div class="login_form">
            <form>
               <div class="mb-4">
                  <div class="email_field">
               <v-text-field hide-details
                  v-model="first"
                  label="First Name"
                  outlined
               ></v-text-field>
                  </div>
               </div>
               <div class="mb-4">
                  <div class="email_field">
               <v-text-field hide-details
                  v-model="email"
                  label="E-mail"
                  outlined
               ></v-text-field>
                  </div>
               </div>
               <div class="mb-4">
                  <div class="email_field">
                     <v-text-field hide-details
                        v-model="phoneNumber"
                        label="Phone No"
                        outlined
                     ></v-text-field>
                  </div>
               </div>
               <div class="mb-4">
                  <div class="email_field">
                     <v-select hide-details
                        :items="items"
                        label="UKG"
                        outlined
                     ></v-select>
                  </div>
               </div>
               <div class="mb-4">
                  <div class="email_field">
                                <v-text-field hide-details
            v-model="school"
            label="ABC Primary School"
            outlined
          ></v-text-field>
                  
                  </div>
               </div>
               <div class="mb-4">
                  <div class="email_field">
                     <v-text-field hide-details
            label="Bangalore"
            append-icon="mdi-map-marker"
              outlined
          ></v-text-field>
                  
                  </div>
               </div>
               <div class="mb-4">
                  <div class="email_field">
                    <v-menu hide-details
                     v-model="menu2"
                     :close-on-content-click="false"
                     :nudge-right="40"
                     transition="scale-transition"
                     offset-y
                     min-width="auto"
                     >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field hide-details
            v-model="date"
            label="Picker without buttons"
           
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
          ></v-text-field>
        </template>
        <v-date-picker hide-details
          v-model="date"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu> 
                  </div>
               </div>
               <div class="mb-4">
                  <div class="email_field">
         <v-text-field hide-details
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            label="Enter Password"
            hint="At least 8 characters"
            value="wqfasds"
            class="input-group--focused"
            outlined
          ></v-text-field>
                  </div>
               </div>
               <div class="mb-4">
                  <div class="email_field">            
                     <v-textarea
                        name="input-7-1"
                        label="Message"
                        value=""
                        hint="Hint text"
                        outlined
                     ></v-textarea>
                     </div>
               </div>
               <div class="login_buttons">
                  <div class="row">
                     <div class="col-lg-6 col-md-6 col-6">
                     <v-btn depressed>
                       Cancel
                       </v-btn>
 
                     </div>
                     <div class="col-lg-6 col-md-6 col-6">
                           <v-btn
                         depressed
                         color="primary">
                         Save
                         </v-btn>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</section>
      </div>
   </div>
</template>

<script>
import Vue from 'vue'
import vUploader from 'v-uploader';


// v-uploader plugin global config
const uploaderConfig = {
  // file uploader service url
  uploadFileUrl: 'http://xxx/upload/publicFileUpload',
  // file delete service url
  deleteFileUrl: 'http://xxx/upload/deleteUploadFile',
  // set the way to show upload message(upload fail message)
  showMessage: (vue, message) => {
    //using v-dialogs to show message
    vue.$dlg.alert(message, {messageType: 'error'});
  }
};

// install plugin with options
Vue.use(vUploader, uploaderConfig);
  export default {
     name: "Edit-profile",
    data: () => ({
      first: 'John',
      email:'Munirajthammapan@gmail.com',
      phoneNumber:'9487782421',
       items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      password:'',
    }),
      methods: {
    uploadDone(files){
      if(files && Array.isArray(files) && files.length){
        console.log(files);
      }
    }
  }
  }
</script>

<style></style>