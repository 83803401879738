var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"after_lofin_section"},[_c('section',{staticClass:"edit_profile"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"exsports_main_login"},[_c('div',{staticClass:"exsports_edit_profile_title"},[_c('div',{staticClass:"login_top_sec"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../assets/images/login-logo.svg"),"alt":"Exsport"}})]),_c('h2',[_vm._v("Change password")]),_c('v-card-subtitle',[_vm._v("Please change your password")])],1)]),_c('div',{staticClass:"login_form"},[_c('ValidationObserver',{ref:"passwordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitPassword)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"Current password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input-group--focused",attrs:{"append-icon":_vm.showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"type":_vm.showCurrentPassword ? 'text' : 'password',"name":"Current password","label":"Current Password","hint":"At least 5 characters","outlined":"","dense":""},on:{"click:append":function($event){_vm.showCurrentPassword = !_vm.showCurrentPassword}},model:{value:(_vm.formData.currentPassword),callback:function ($$v) {_vm.$set(_vm.formData, "currentPassword", $$v)},expression:"formData.currentPassword"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"New password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input-group--focused",attrs:{"append-icon":_vm.showNewPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"type":_vm.showNewPassword ? 'text' : 'password',"name":"New password","label":"New Password","hint":"At least 5 characters","outlined":"","dense":""},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},model:{value:(_vm.formData.newPassword),callback:function ($$v) {_vm.$set(_vm.formData, "newPassword", $$v)},expression:"formData.newPassword"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Confirm password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input-group--focused",attrs:{"append-icon":_vm.showconfirmPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"type":_vm.showconfirmPassword ? 'text' : 'password',"name":"Confirm password","label":"Confirm Password","hint":"At least 5 characters","outlined":"","dense":""},on:{"click:append":function($event){_vm.showconfirmPassword = !_vm.showconfirmPassword}},model:{value:(_vm.formData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.formData, "confirmPassword", $$v)},expression:"formData.confirmPassword"}})]}}],null,true)}),_c('div',{staticClass:"login_buttons"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-6"},[_c('v-btn',{staticClass:"oragne_cancel",attrs:{"depressed":""},on:{"click":_vm.resetpassword}},[_vm._v(" Cancel ")])],1),_c('div',{staticClass:"col-lg-6 col-md-6 col-6"},[_c('v-btn',{staticClass:"oragne_buttons",attrs:{"text":"","depressed":""},on:{"click":_vm.submitPassword}},[_vm._v(" Save ")])],1)])])],1)]}}])})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }