<template>
  <div id="home" class="pa-4">
    <v-container fluid>
      <v-alert prominent type="error" v-if="errored">
        <v-row align="center">
          <v-col class="grow">
            <div class="title">Error!</div>
            <div>
              Something went wrong, but don’t fret — let’s give it another shot.
            </div>
          </v-col>
          <v-col class="shrink">
            <v-btn @click="getVideos">Take action</v-btn>
          </v-col>
        </v-row>
      </v-alert>

    <h3 class="common_headline">{{ this.categoryname }}</h3>

        <VueSlickCarousel v-bind="settings">
          <div v-for="(video, i) in loading ? 12 : videos" :key="i" >
           <div class="home_video_list_common">
            <v-skeleton-loader type="card-avatar" :loading="loading">
              <video-card
                :card="{ maxWidth: 350 }"
                :video="video"
                :channel="video.userId"
              ></video-card>
            </v-skeleton-loader>
          </div>
          </div>
          <v-col class="text-center" v-if="videos.length === 0 && !loading">
            <p>No videos yet</p>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <infinite-loading @infinite="getVideos">
              <div slot="spinner">
                <v-progress-circular
                  indeterminate
                  color="red"
                ></v-progress-circular>
              </div>
              <div slot="no-results"></div>
              <span slot="no-more"></span>
              <div slot="error" slot-scope="{ trigger }">
                <v-alert prominent type="error">
                  <v-row align="center">
                    <v-col class="grow">
                      <div class="title">Error!</div>
                      <div>
                        Something went wrong, but don’t fret — let’s give it
                        another shot.
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn @click="trigger">Take action</v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </div>
            </infinite-loading>
          </v-col>

</VueSlickCarousel>
    </v-container>




  </div>
  
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import moment from 'moment'

import VideoCard from '@/components/VideoCard'
import VideoService from '@/services/VideoService'
import CategoryService from '@/services/CategoryService'


  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Home',
  
  model: null,
  
  data: () => {
     
    return {
            categoryname: {},
            active: false,
            loading: false,
            loaded: false,
            errored: false,
            videos: [],
            trendingvideos: [],
              url: process.env.VUE_APP_API_URL,

            page: 1,
            settings: 
            {
              "dots": true,
              "infinite": false,
              "speed": 500,
              "slidesToShow": 5,
              "slidesToScroll": 2,
              "initialSlide": 0,
              "responsive": [
                {
                  "breakpoint": 1024,
                  "settings": {
                    "slidesToShow": 3,
                    "slidesToScroll": 3,
                    "infinite": true,
                    "dots": false
                  }
                },
                {
                  "breakpoint": 600,
                  "settings": {
                    "slidesToShow": 2,
                    "slidesToScroll": 2,
                    "initialSlide": 2
                  }
                },
                {
                  "breakpoint": 480,
                  "settings": {
                    "slidesToShow": 1,
                    "slidesToScroll": 1
                  }
                }
              ]
            },

    }    
  },  
  

  methods: {    
     toggle () {
        this.active = !this.active
      },
    async getVideos($state) {
      if (!this.loaded) {
        this.loading = false
      }
      const videos = await VideoService.getAll('public', { page: this.page,categoryId:this.$route.params.id })
        .catch((err) => {
          console.log(err)
          this.errored = true
        })
        .finally(() => {
          this.loading = false
        })

      if (typeof videos === 'undefined') return
          // console.log(videos.data.data)

      if (videos.data.data.length) {
        this.page += 1
        this.videos.push(...videos.data.data)
        $state.loaded()
        this.loaded = true
      } else {
        $state.complete()
      }
      this.categorypagename()
    },

    async categorypagename()
    {
     var currentUrl = window.location.pathname;
      
      const splitData = currentUrl.split('/')
      const id = splitData[2]

      const categories = await CategoryService.getCategoryById(id)
      .catch((err) => {
          this.errored = true
          console.log(err)
          this.$router.push('/')
        })
        .finally(() => (this.loading = false))

       this.categoryname = categories.data.data.title

    },

    dateFormatter(date) {
      return moment(date).fromNow()
    }
    
  },
   created()
    {
      // this.gettrendingVideos()
      this.getVideos()
      this.categorypagename()
    },
  components: {
    VideoCard,
    InfiniteLoading,
    VueSlickCarousel
  }
  

  
}

</script>

<style lang="scss">
</style>