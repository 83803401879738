

<script>
export default {
  name: 'SignIn',
  data: () => ({
    token: ''
    
  }),
  methods: {
   async verify() {
  
      if (this.loading) return
      this.loading = true
      const data = await this.$store.dispatch('verify', {verification: this.$route.query.token })
      .then((response) => {
            console.log(response)
                     if (response.status === 200) {
                             this.email='',
                             this.loading = false
              this.$toasted.show("Your account is activated", {
                theme: "bubble",
                position: "bottom-right",
                type: "success",
                duration: 5000,
              });
            this.$router.push({ name: 'Home' })

                     }
                })
       
                 .catch(errors => {
                this.$toasted.show(errors, {
                  theme: "bubble",
                  position: "bottom-right",
                  errors: "errors",
                  singleton: true,
                  duration: 5000
                });
              })

      if (!data) return
      const user = await this.$store
        .dispatch('getCurrentUser', data.token)
        .catch((err) => console.log(err))

      if (!user) return

      this.loading = false
      this.$router.push({ name: 'Home' })
    }
  },
  created(){
    this.verify();
  }
}
</script>

