var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('section',{staticClass:"reach_us"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"reachus-common"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-7 col-md-7 col-sm-12 col-12 padding0"},[_c('div',{staticClass:"reachus_form"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.Contact)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"phoneNumber","rules":{  required: true  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Phone Number"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"message","rules":{
                           required: true
                         }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Message","hint":"Hint text"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"gogolecaptch"},[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"size":"compact ","sitekey":_vm.siteKey,"loadRecaptchaScript":true,"recaptchaHost":"www.recaptcha.net"},on:{"verify":_vm.onCaptchaVerified,"expired":_vm.onCaptchaExpired}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('v-btn',{staticClass:"orange--text",attrs:{"type":"submit"}},[_vm._v(" submit ")])],1)])])]}}])})],1)]),_vm._m(1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact_us_title"},[_c('h1',[_vm._v("Contact us")]),_c('p',[_vm._v("Drop us a message and we'll get back to you.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-5 col-md-5 col-sm-12 col-12 padding0"},[_c('div',{staticClass:"reachus_bg"},[_c('div',{staticClass:"reachus_head"},[_c('h2',[_vm._v("Reach us!")])]),_c('hr'),_c('div',{staticClass:"reachus_address"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-3 col-3"},[_c('img',{attrs:{"src":require("../assets/images/location.png"),"alt":"location"}})]),_c('div',{staticClass:"col-lg-9 col-md-9 col-sm-9 col-9"},[_c('a',{attrs:{"href":"#"}},[_c('p',[_vm._v("#345, Kariyanpalya, Lingarajapuram, Bangalore – 560084. ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-3 col-3"},[_c('img',{attrs:{"src":require("../assets/images/mail.png"),"alt":"email"}})]),_c('div',{staticClass:"col-lg-9 col-md-9 col-sm-9 col-9"},[_c('a',{attrs:{"href":"mailto:info@pupiltube.space"}},[_c('p',[_vm._v("info@pupiltube.space")])])])]),_c('div',{staticClass:"reachus_phone"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-3 col-3"},[_c('img',{attrs:{"src":require("../assets/images/call.png"),"alt":"phone"}})]),_c('div',{staticClass:"col-lg-9 col-md-9 col-sm-9 col-9"},[_c('a',{attrs:{"href":"#"}},[_c('p',[_vm._v("+91 080 41104089 / 25470101")])])])])])])])])}]

export { render, staticRenderFns }