<template>
<div class="Help-support">
<section class="common_bg_color">
 <div class="container">
    
   <div class="row">
                    <div class="col-md-2" >
                  <div class="setting_nav">
                     <h2>Settings</h2>
                     <ul>
                          <li class="active"><router-link to="/profile/edit">Edit Profile</router-link></li>
                           <li><router-link to="/help-support">Help &amp; Support</router-link></li>
                           <li><router-link to="/provide-feedback">Provide Feedback</router-link></li>
                     </ul>
                  </div>
               </div>
               <div class="col-md-10">
                  <div class="edit_profile">
                     <h1>Help &amp; Support</h1>
                     <div class="edit_profile_content">
                            <h2>All Tickets <span class="uplode_icon"><a  v-b-modal.modal-lg><b-icon icon="plus-circle"></b-icon></a></span></h2>

                             <div class="common_popup_editor">
                                   <b-modal id="modal-lg" size="lg" title="Add Tickets">
                                 <div class="popup_editore_content">
                                    <form>
                                        <div class="row">
                                           <div class="col-md-12">
                                              <div class="group_mt">
                                          <input type="text"  v-model="new_conversation['title']" class="form-control" placeholder="Subject">
                                           </div>
                                           </div>
                                           <div class="col-md-12">
                                               <div class="group_mt">
                                              <!-- <ckeditor  v-model="new_conversation['description']"></ckeditor> -->
                                              <wysiwyg v-model="new_conversation['description']" />
                                           </div>
                                           </div>
                                            <div class="col-md-12">
                                               <div class="group_mt">
                                               <div class="attachment_tickets">
                                           <b-form-file
                                             v-model="tmpAttachment"
                                             :state="true"
                                            placeholder="Attachment"
                                            drop-placeholder="Drop file here..."
                                          ></b-form-file>
                                            
                                          </div>
                                          </div>
                                             </div>

                                         </div>
                                     </form>
                                  </div>

                                <template #modal-footer>
                                   <b-button
                                  size="sm"
                                  class="float-right"
                                  @click="cancel">
                                Cancel
                               </b-button>
                         
                              <b-button
                                  variant="primary"
                                  size="sm"
                                  class="float-right"  @click="save">
                                Submit
                               </b-button>
                               
                             </template>
                         </b-modal>
                       </div>

                            
                     
                     <div class="table_content_support">
                       <table :data="pageData.items" :sst="true" ref="table" 
                     :total="pageData.total"
                      :max-items="pageData.page_size" noDataText="" stripe pagination search>
                       <tbody>
                        <tr :data="pageData.items" :key="indextr" v-for="(tr, indextr) in pageData.items">
                          <th scope="row">
                      <div class="client_initial">
                        <h4>J</h4>
                        </div>
                        </th>
                          <td> <div class="client_id">
                             <router-link :to="'/provide-feedback-info/'+tr._id">
                                             <h4> {{ pageData.items[indextr].ticket_id }}</h4>
                                             <p>{{ pageData.items[indextr].description}}</p>
                                             </router-link>
                                          </div></td>
                          <td><div class="client_id">
                                             <h4>{{ pageData.items[indextr].title }}</h4>
                                             <label>{{ pageData.items[indextr].description }}</label>
                                          </div></td>
                          <td width="140"><div class="attachment_tickets">
                             <b-form-file
                              
                              placeholder="Attachment"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                                            
                                          </div></td>
                          <td>
                             <div class="three_dots_menu">
                                            <a href="/ticket-details">
                                               <b-icon icon="three-dots-vertical"></b-icon>		</a>			 
                                          </div>
                                          </td>
                        </tr>
                             <!--  <tr>
                          <th scope="row">
                       <div class="client_initial">
                        <h4>K</h4>
                        </div>
                        </th>
                          <td> <div class="client_id">
                                             <h4>#10KUE64</h4>
                                             <p>Jane Dough</p>
                                          </div></td>
                          <td><div class="client_id">
                                             <h4>Support</h4>
                                             <label>Unable to added replies</label>
                                          </div></td>
                          <td width="140"><div class="attachment_tickets">
                             <b-form-file
                              v-model="file1"
                              :state="Boolean(file1)"
                              placeholder="Attachment"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                                            
                                          </div></td>
                          <td>
                             <div class="three_dots_menu">
                                            <a href="#">
                                               <b-icon icon="three-dots-vertical"></b-icon>		</a>			 
                                          </div>
                                          </td>
                        </tr>
                               
                       -->
                      </tbody>
                       </table>
                      
                     <b-pagination pills  align="right" :total="pageData.total_pages" v-model="pageData.page" :key="renderKey" ></b-pagination>
                     
                     </div>
                    
                     </div>
                  </div>
               </div>

          </div>
         </div>

   </section>
   </div>
   </template>
<script>


// Tell Vue to install the plugin.
//Vue.use(VuejsDialog);

export default {
}
</script>
