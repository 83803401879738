<template>
  <div id="home" class="pa-4">
    <v-container fluid>
      <v-alert prominent type="error" v-if="errored">
        <v-row align="center">
          <v-col class="grow">
            <div class="title">Error!</div>
            <div>
              Something went wrong, but don’t fret — let’s give it another shot.
            </div>
          </v-col>
          <v-col class="shrink">
            <v-btn @click="getVideos">Take action</v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <main v-else>
       
         <div class="main_video_list"  >
            <h3 class="liked_video_title">Liked video</h3>
     <v-row>
     <v-col cols="12" sm="6" md="4" lg="3">
        
    <div class="play_all_video" v-for="item in slicedArray" :key="item._id" >
      <v-card 
    class="content-bg card mx-auto"
    :max-width="350"
    flat
    tile
    router
    :to="`/watch/${item._id}`">
  <v-img :src="`${item.thumbnailUrl}`">  <div class="watch_timer" v-if="item.duration != 0">{{getTwoDecimal(item.duration)}}</div></v-img>   </v-card> 
  <div class="paly_label">PLAY ALL
 
</div>
    </div>  
    <div class="video_info_bottom" v-for="item in slicedArray" :key="item.id">
        <label>{{videos.length}} videos . {{item.views}} views . {{ dateFormatter(item.createdAt) }}</label>
    <v-btn depressed>
       <v-icon>mdi-lock-outline</v-icon> Private
    </v-btn>
  <div class="shafull_section">
      <router-link to="#" class="shafulle_icone">
    <v-icon>mdi-repeat</v-icon>
    </router-link>
  </div>
    </div>
    </v-col>
     <v-col cols="12" sm="6" md="8" lg="8">
        <v-row v-for="(video, i) in loading ? 12 : videos"
            :key="i">
          <v-col cols="12" sm="6" md="3" lg="3">

            <v-skeleton-loader type="card-avatar" :loading="loading">
              <video-card>
           <v-card
    class="content-bg card mx-auto"
    :max-width="350"
    flat
    tile
    router
    :to="`/watch/${video._id}`"
  >
    <v-img :src="`${video.thumbnailUrl}`">  <div class="watch_timer" v-if="video.duration != 0">{{getTwoDecimal(video.duration)}}</div></v-img>
         <div class="video_titles_top">
          <v-card-title
          class="pl-2 pt-3 video_titles_top"
          style="line-height: 1.2rem"
        >
        </v-card-title>
     </div>
    <v-row no-gutters>

     
      <v-col>
      
      </v-col>
    </v-row>
  </v-card>
          </video-card>

            </v-skeleton-loader>

          </v-col>
          <v-col cols="12" sm="6" md="9" lg="9">
            <div class="right_content_info">
              <h4>          {{ video.title }}</h4>
              <p> {{ video.description }}</p>
            </div>
          </v-col>
          </v-row>  
    </v-col>
     </v-row>
        </div>



        <v-row>
          
          <v-col class="text-center" v-if="videos.length === 0 && !loading">
            <p>No liked videos yet</p>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <infinite-loading @infinite="getVideos">
              <div slot="spinner">
                <v-progress-circular
                  indeterminate
                  color="red"
                ></v-progress-circular>
              </div>
              <div slot="no-results"></div>
              <span slot="no-more"></span>
              <div slot="error" slot-scope="{ trigger }">
                <v-alert prominent type="error">
                  <v-row align="center">
                    <v-col class="grow">
                      <div class="title">Error!</div>
                      <div>
                        Something went wrong, but don’t fret — let’s give it
                        another shot.
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn @click="trigger">Take action</v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </div>
            </infinite-loading>
          </v-col>
        </v-row>
      </main>
    </v-container>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'

import FeelingService from '@/services/FeelingService'
import moment from 'moment'
export default {
  name: 'LikedVideo',
  data: () => ({
    loading: false,
    loaded: false,
    errored: false,
    videos: [],
    mostlikedvideos:[],
        card: Object,

    page: 1,
    url: process.env.VUE_APP_API_URL,

  }),
  methods: {
    async getVideos($state) {
      if (!this.loaded) {
        this.loading = true
      }

      const videos = await FeelingService.getLikedVideos(this.page)
        .catch((err) => {
          console.log(err)
          this.errored = true
        })
        .finally(() => {
          this.loading = false
        })
      if (typeof videos === 'undefined') return

      if (videos.data.data.length) {
        this.page += 1
        this.mostlikedvideos = videos.data.data
        this.videos = videos.data.data.slice(1)

        $state.loaded()
        this.loaded = true
      } else {
        $state.complete()
      }
    },
    dateFormatter(date) {
      return moment(date).fromNow()
    },
      getTwoDecimal(duration){
return (duration/100).toFixed(2);   
 }
  },
computed: {
    slicedArray() {
      return this.mostlikedvideos.slice(0, 1)
    }
  },
  components: {
    InfiniteLoading
  }
}
</script>

<style lang="scss">
.card {
  background: #f9f9f9 !important;
}
</style>
