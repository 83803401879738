<template>
   <div class="">
      <div class="terms_cms">
         <div class="container contwith1170">
            <div class="terms_and_conditions_title">
               <h1>Privacy policy</h1>
            </div>
            <v-card>
               <v-tabs vertical>
                  <div class="terms_tabs">
                     <h4>Table of Contents</h4>
                     <v-tab>
                        Privacy Policies
                     </v-tab>
                     <v-tab>
                        Privacy Guarantee
                     </v-tab>
                       <v-tab>
                     Collection of Information
                     </v-tab>
                     <v-tab>
                     Security
                     </v-tab>
                     <v-tab>
                      Changes in policy
                     </v-tab>
                     <v-tab>
                       Liability of Learncast.in
                     </v-tab>
                     <v-tab>
                        Notice
                     </v-tab>
                     <v-tab>
                       Dispute Resolution
                     </v-tab>
                     <v-tab>
                       Waiver
                     </v-tab>
                         <v-tab>
                       Severability
                     </v-tab>
                       <v-tab>
                       Relationship between the Parties
                     </v-tab>
                       <v-tab>
                       Headings
                     </v-tab>
                      <v-tab>
                       Amendments to this Agreement
                     </v-tab>
                  </div>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Table of Contents</h3>
                            <h3>How we use information</h3>
<p> a. <strong>Personal Information </strong> - The personal information you provide to us when using the application, such as your name, postal, e-mail address or telephone number will be kept confidential and used to support your relationship with Learncast to notify you of special offers, updated information and new products and services from Learncast, or offers from third parties that we think may be of interest to you. Agents or contractors of Learncast, who are given access to your personal information will be required to keep the information confidential and not use it for any other purpose other than to carry out the services they are performing for Learncast..</p>

<p>b. <strong>Camera</strong> - Learncast requires Camera access to enable app main features like, photos taken using camera will be used only for the mentioned use cases. This feature is used to upload user profile pictures.</p>

<p>c. <strong>Storage permission</strong> – Learncast requires storage permission to enable app main features like, photos taken from gallery will be used only for the mentioned use cases. This feature is used to upload user profile pictures.</p>
<h3>How Do We Secure Information Transmissions?</h3>
<p>Email is not recognized as a secure medium of communication. For this reason, we request that you do not send private information to us by email. This Site does not, however, provide facilities for the secure transmission of information across the Internet. You should be aware that there are inherent risks associated with the transmissions of Personal Information via the Internet.</p>
<p>If you do not wish to use the Internet to transmit personal information you can mail or phone us, Details are provided under ‘How to Contact Us.</p>
<h3>How Can You Access and Correct Your Information?</h3>
<p>Email is not recognized as a secure medium of communication. You may request access to all your personally identifiable information that we collect online and maintain in our database by calling Learncast.</p>
<h3>Certain Disclosures</h3>
<p>We may disclose your personal information if required to do so by law or subpoena or if we believe that such action is necessary to (a) conform to the law or comply with the legal process served on us or affiliated parties; (b) protect and defend our rights and property, our site, the users of our site, and/or our affiliated parties; (c) act under circumstances to protect the safety of users of our site, us, or third parties.</p>
<h3>What About Other Web sites Linked to Our Web site?</h3>
<p>We are not responsible for the practices employed by Web sites linked to or from our Web site nor the information or content contained therein. Often links to other Web sites are provided solely as pointers to information on topics that may be useful to the users of our Web site.</p>
<p>Please remember that when you use a link to go from our Web site to another Web site, our Privacy Policy is no longer in effect. Your browsing and interaction on any other Web site, including Web sites which have a link to our Web site, is subject to that Web site’s own rules and policies. Please read over those rules and policies before proceeding.</p>
<h3>Children’s Privacy</h3>
<p>We do not knowingly collect personal information from children below the age of 15. If we learn that we have personal information on a child below the age of 15, we will delete that information from our systems. Even for the personal information of children of the age of 15 years and above, the personal information is collected only for the purpose of their al requirements and service needs as provided by this Web site.</p>
<h3>Spamming</h3>
<p>Sending unsolicited mail messages, including, without limitation, commercial advertising and informational announcements, is expressly prohibited. A user shall not use another site’s mail server to relay mail without the express permission of the site.</p>
<h3>It is contrary to Learncast policy for customers to use our servers to effect or participate in any of the following activities:</h3>
<p>To post to any Usenet or other newsgroup, forum, e-mail mailing list or other similar group or list articles which are off-topic according to the agreement or other owner-published FAQ or description of the group or list;
To send unsolicited mass e-mailings, if such unsolicited e-mailings provoke complaints from the recipients;
To engage in any of the foregoing activities using the service of another provider, but channeling such activities through Learncast provided server, or using Learncast provided server as a mail drop for responses;
To falsify user information provided to Learncast or to other users of the service in connection with the use of Learncast service.</p>
<h3>Consequences of Violation:</h3>
<p>When Learncast becomes aware of an alleged violation of its Acceptable Use Policy, Learncast will initiate an investigation. During the investigation, Learncast may restrict the Customer‘s access to prevent further possible unauthorized activity. Depending on the severity of the violation, Learncast may, at its sole discretion, restrict, suspend, or terminate the Customer’s account and pursue other civil remedies. If such a violation is a criminal offence, Learncast will notify the appropriate law enforcement department of such a violation.</p>
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Acceptance of terms of use and other policies</h3>
                              <p>
                                Learncast.in agree that the information shared by you will be protected and will not be sold to any third parties for their marketing purposes without your explicit consent.
                              </p>
                              <p>
                                 Only our employees who need access to your information in order to perform their duties are allowed such access. Any employee who violates our privacy and/or security policies is subject to disciplinary action, including possible termination.
                              </p>
                              
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Use of platform and services</h3>
                              <p>
                                When you use our Site, Learncast.in collect and store your personal information from you. Our primary goal in doing so is for the purposes of provisioning our services.
                              </p>
                              <p>
                                In general, you can browse the Site without revealing any personal information about yourself. To fully use our Site, you will need to register using our online registration form and you may be required to provide your details like your name, date of birth, contact number, email id, user id, password, residence / place of business information and other personal information as indicated on the forms throughout the Site. You always have the option to not provide information by choosing not to use a particular service or feature on the Site.
                              </p>
                              
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Security</h3>
                              <p>
                                The data collected is protected against unauthorized access to prevent unauthorized access, maintain data accuracy and ensure correct use of information in compliance with Information Technology Act and rules made there under.
                              </p>
                           
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Changes in policy</h3>
                              <p>
                                The Privacy Policy is part of the User Agreement and you unconditionally agree that using this Site signifies your assent to our Privacy Policy. If you do not agree with this Privacy Policy, please do not use our Site. Your visit and any dispute over privacy is subject to this policy and the User Agreement, including limitations on damages. We reserve the right to change the terms of use and this Privacy Policy, at any time. We will post any changes so that you are always aware of our policy. Unless stated otherwise, our current Privacy Policy applies to all information that we have about you and your
account.
                              </p>
                              
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Liability of Learncast.in</h3>
                              <p>
                                It is agreed between the Parties that the learncast.in shall not be liable for any consequential or indirect for any wrong, misleading, etc of the postings uploaded by the User/s.
                              </p>
                              
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Use of platform and services</h3>
                              <p>
                                 Any notice required or permitted to be given hereunder shall be in writing and shall be effectively given if (i) sent by prepaid courier service, airmail, registered mail or registered post with acknowledgment due (RPAD); or (ii) sent prepaid by facsimile or other similar means of electronic communication (with confirmed receipt), to the Parties at the address as mentioned below.
                              </p>
                              <p>Learncast.in</p>
                              <p>#345, Kariyanpalya, Lingarajapuram, Bangalore – 560084.</p>
                              <p>Email:<a href="mainto:info@learncast.in">info@learncast.in</a></p>
<p>Email:<a href="mainto:info@learncast.in">info@learncast.in</a></p>
<p>Mobile No:<a href="tel:9845375030">9845375030</a></p>
<p>YAHWEH Soft Tech Pvt Ltd</p>
		
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Dispute Resolution</h3>
                              <p>
                                 Either Party shall give written notice pertaining to the dispute/difference to the other Party within Five (05) days of the occurrence of the event which gave rise to such dispute/difference or the day that such event came to the notice of the applicable Party. Then the Parties shall put their best attempt/effort to amicably settle/resolve such dispute and/or difference raised out of this Agreement.
                              </p>
                              <p>
                                In the event, if the Parties fail to amicably settle and / or resolve such dispute and/or differences within Thirty (30) days from the date when the dispute arose, then such dispute/difference shall be referred to a sole Arbitrator to be appointed mutually by the Parties.
                              </p>
                              <p>
                                The seat of arbitration shall be at Bangalore/Bengaluru, Karnataka, India and the award of the Arbitrator shall be final and binding on the Parties.
                              </p>
                              <p>
                                 The arbitration proceedings shall be in English language and governed by the Arbitration &amp; Conciliation Act, 1996 or any statutory modifications, amendments, re-enactment thereof shall apply to such arbitration.
                              </p>
                              <p>
                                 Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical  
                                 Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at  
                                 Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur,  
                                 from a Lorem Ipsum passage, and going through the cites of the word in classical literature,  
                                 discovered the undoubtable source.
                              </p>
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>

                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Waiver</h3>
                              <p>
                                 Any delay or indulgence by either Party in enforcing the terms and conditions of this Agreement or any forbearance or giving time to the other shall not be construed as a waiver on the part of the other Party or any breach or non-compliance of any of the terms and conditions of this Agreement by either Party shall in any manner prejudice the rights of the other Party.
                              </p>
                              
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                        <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Severability</h3>
                              <p>
                                 If one or more of the provisions hereof shall be held void, invalid, illegal or unenforceable in any respect under any applicable law, the validity, legality and enforceability of the remaining provisions herein contained shall not be affected or impaired in any way.
                              </p>
                              
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                      <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Relationship between the Parties</h3>
                              <p>
                                This Agreement has been entered into on a principal-to-principal basis and nothing in this Agreement shall be construed as creating a partnership or joint venture between the Parties. Neither Party will be deemed to be an agent of the other Party as a result of any act under or related to this Agreement.
                              </p>
                              
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                   <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Headings</h3>
                              <p>
                                The headings contained in this Agreement are for reference purpose only and shall not in any way affect the meaning or interpretation of this Agreement.
                              </p>
                              
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                    <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Amendments to this Agreement</h3>
                              <p>
                                The Learncast reserves the right to amend the clauses at any time and/or time to time as required due to the up gradations of services, offers, operations, etc. And such Amendment/s version shall take effect immediately and applicable to the User/s.

                              </p>
                              
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
               </v-tabs>
            </v-card>
         </div>
      </div>
   </div>
</template>
<script>
   export default {
     name: "Privacy",
   
      data() {
    return {
      value: ""
    };
  },
     methods: {
    
     },
   };
</script>
<style></style>