<template>
   <div class="">
      <div class="terms_cms">
         <div class="container contwith1170">
            <div class="terms_and_conditions_title">
               <h1>Please read the below terms &amp; conditions carefully before downloading the Application and accessing the Services of PupilTube.</h1>
            </div>
            <v-card>
               <v-tabs vertical>
                  <div class="terms_tabs">
                     <h4>Information</h4>
                     <v-tab>
                        Information
                      
                     </v-tab>
                     <v-tab>
                         Terms &amp; Conditions (T&amp;C)
                      
                     </v-tab>
                     <v-tab>
                         Agreement between the Parties
                        
                     </v-tab>
                     <v-tab>
                        Services of PupilTube
                     
                     </v-tab>
                     <v-tab>
                          Promotions &amp; Access
                     
                     </v-tab>
                     <v-tab>
                         General Policy
                       
                     </v-tab>
                  </div>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Introduction</h3>
                              <p>
                                PupilTube (herein after referred to as “WE”) are in the business of providing video platform for schools and sports institutions to showcase their sports events. Further we provide space in our network for you to add, paste, promote, etc the information/activities pertaining to the sports.
                              </p>
                              <p>
                                 We provide our services 24/7 at free of cost.
                              </p>
                              <strong>Restriction:</strong>
                              <p>
                                 Only competent persons shall register and use the website.
                              </p>
                              <p>
                                Any activities which are notified, restricted and/or banned and/or prohibited from time to time (including but not limited to animals, humans, firearms, ammunition, animations, human remains, pornography and illegal narcotics/drugs) shall not be uploaded in the PupilTube.
                              </p>
                              <p>
                                 Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical 
                                 Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at  
                                 Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, 
                                 from a Lorem Ipsum passage, and going through the cites of the word in classical literature, 
                                 discovered the undoubtable source.
                              </p>
                     
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3> Terms &amp; Conditions (T&amp;C)</h3>
                              <p>
                                Mean the obligation on the Parties to perform their respective duties during the
                              Agreement.
                              </p>
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Agreement between the Parties</h3>
                              <p>
                                 A. The User/s agree that he/she/they have entered into this agreement only after verifying about the PupilTube's offers, polices, terms and conditions, warranties, etc.
                              </p>
                              <p>
                                 B. And further agrees that only after the satisfaction, the User/s has browsed the website / downloaded the App and using/accessing the application either once and/or multiple times and has established contractual relationship between the Parties.
                              </p>
                              <p>
                                 C. The User/s acknowledges that the PupilTube shall have all right to terminate/amendment/supplement the terms and conditions, services, offers, tariffs, slots, polices, warranties, deny of services, access, etc at any time for any reason.
                              </p>
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Services of PupilTube</h3>
                              <p>
                                After accepting the terms and conditions and after knowing/reading the liabilities of the PupilTube, the PupilTube has provided/granted license to the User/s to access the Application and procure the services of the PupilTube.
                              </p>
                             
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>Promotions &amp; Access</h3>
                              <p>
                                If you choose to post messages on our message boards, chat rooms or other message areas or leave feedback for other users, we will collect that information you provide to us and display the same. You can promote your sports videos in the website and same can be watched, viewed, downloaded, etc by any one from the web site. The PupilTube can be viewed all over the world.
                              </p>
                           
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card flat>
                        <v-card-text>
                           <div class="terms_and_conditions_content">
                              <h3>General Policy</h3>
                              <p>
                                PupilTube shall have all/full rights to delete the contents, activities, etc posted in the PupilTube without any notice to the concerned person.
                              </p>
                              <p>
                                PupilTube shall have all/full rights to take legal action against the person/s, companies, trusts, etc who violates the rules and regulations of Information Technology Act, IPR, etc.
                              </p>
                              
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-tab-item>
               
       
               </v-tabs>
            </v-card>
         </div>
      </div>
   </div>
</template>
<script>
   export default {
     name: "terms",
   
      data() {
    return {
      value: ""
    };
  },
     methods: {
    
     },
   };
</script>
<style></style>