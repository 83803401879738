<template>
  <div id="home" class="home_page_video_section">
    <v-container fluid>
      <v-alert prominent type="error" v-if="errored">
        <v-row align="center">
          <v-col class="grow">
            <div class="title">Error!</div>
            <div>
              Something went wrong, but don’t fret — let’s give it another shot.
            </div>
          </v-col>
          <v-col class="shrink">
            <v-btn @click="getVideos">Take action</v-btn>
          </v-col>
        </v-row>
      </v-alert>

    <h3 class="common_headline">Latest Videos</h3>
        <VueSlickCarousel v-bind="settings">
              <div 
                v-for="(video, i) in loading ? 12 : videos"
                :key="i" >
              <div class="home_video_list_common">
                <v-skeleton-loader type="card-avatar" :loading="loading">
                  <video-card
                    :card="{ maxWidth: 350 }"
                    :video="video"
                    :channel="video.userId"
                  ></video-card>
                </v-skeleton-loader>
              </div>
          </div>
          <v-col class="text-center" v-if="videos.length === 0 && !loading">
            <p>No videos yet</p>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">

            <infinite-loading @infinite="getVideos">
              <div slot="spinner" style="display:block">
                <v-progress-circular
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </div>
              <div slot="no-results"></div>
              <span slot="no-more"></span>
              <div slot="error" slot-scope="{ trigger }">
                <v-alert prominent type="error">
                  <v-row align="center">
                    <v-col class="grow">
                      <div class="title">Error!</div>
                      <div>
                        Something went wrong, but don’t fret — let’s give it
                        another shot.
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn @click="trigger">Take action</v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </div>
            </infinite-loading>
          </v-col>
  <div></div> <div></div> <div></div> <div></div> <div></div> <div></div>
</VueSlickCarousel>

  <h3 class="common_headline">Trending Videos</h3>
        
        <VueSlickCarousel v-bind="settings">
               <div 
            v-for="(video, i) in loading ? 12 : trendingvideos"
            :key="i" >
           <div class="home_video_list_common">
            <v-skeleton-loader type="card-avatar" :loading="loading">
              <video-card
                :card="{ maxWidth: 350 }"
                :video="video"
                :channel="video.userId"
              ></video-card>
            </v-skeleton-loader>
          </div>
          </div>
          <v-col class="text-center" v-if="videos.length === 0 && !loading">
            <p>No videos yet</p>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <infinite-loading @infinite="trendingvideos">
              <div slot="spinner">
                <v-progress-circular
                  indeterminate
                  color="red"
                ></v-progress-circular>
              </div>
              <div slot="no-results"></div>
              <span slot="no-more"></span>
              <div slot="error" slot-scope="{ trigger }">
                <v-alert prominent type="error">
                  <v-row align="center">
                    <v-col class="grow">
                      <div class="title">Error!</div>
                      <div>
                        Something went wrong, but don’t fret — let’s give it
                        another shot.
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn @click="trigger">Take action</v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </div>
            </infinite-loading>
          </v-col>
  <div></div> <div></div> <div></div> <div></div> <div></div> <div></div>
</VueSlickCarousel>

  <h3 class="common_headline">Popular on PupilTube Space</h3>
        <VueSlickCarousel v-bind="settings">
               <div 
            v-for="(video, i) in loading ? 12 : popularvideos"
            :key="i" >
           <div class="home_video_list_common">
            <v-skeleton-loader type="card-avatar" :loading="loading">
              <video-card
                :card="{ maxWidth: 350 }"
                :video="video"
                :channel="video.userId"
              ></video-card>
            </v-skeleton-loader>
          </div>
          </div>
          <v-col class="text-center" v-if="videos.length === 0 && !loading">
            <p>No videos yet</p>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <infinite-loading @infinite="getVideos">
              <div slot="spinner">
                <v-progress-circular
                  indeterminate
                  color="red"
                ></v-progress-circular>
              </div>
              <div slot="no-results"></div>
              <span slot="no-more"></span>
              <div slot="error" slot-scope="{ trigger }">
                <v-alert prominent type="error">
                  <v-row align="center">
                    <v-col class="grow">
                      <div class="title">Error!</div>
                      <div>
                        Something went wrong, but don’t fret — let’s give it
                        another shot.
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn @click="trigger">Take action</v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </div>
            </infinite-loading>
          </v-col>
          <div></div> <div></div> <div></div> <div></div> <div></div> <div></div>
    </VueSlickCarousel>
  
    </v-container>

  </div>
  
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import moment from 'moment'

import VideoCard from '@/components/VideoCard'
import VideoService from '@/services/VideoService'

import Vuetify from 'vuetify';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  vuetify: new Vuetify(),
  name: 'Home',
  model: null,
  data: () => {
  return {
   active: false,
    loading: false,
    loaded: false,
    errored: false,
    videos: [],
    trendingvideos: [],
    popularvideos: [],
    url: process.env.VUE_APP_API_URL,
    page: 1,
         settings: {
        "dots": false,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 5,
        "slidesToScroll": 4,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "infinite": true,
              "dots": false
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      },

    } 
    
  },
  
  methods: {
     toggle () {
        this.active = !this.active
      },
     async getVideos($state) {
       if (!this.loaded) {
         this.loading = true
       }
       const videos = await VideoService.getAll('public', { page: this.page })
         .catch((err) => {
           console.log(err)
           this.errored = true
         })
         .finally(() => {
           this.loading = false
         })
       if (typeof videos === 'undefined') return
           console.log(videos.data.data)

       if (videos.data.data.length) {
         this.page += 1
         this.videos.push(...videos.data.data)
         $state.loaded()
         this.loaded = true
       } else {
         $state.complete()
      }
     },

   /* async getVideos($state) {
      if (!this.loaded) {
        this.loading = true
      }
      const videos = await VideoService.getLast15daysVideos('public')
        .catch((err) => {
          console.log(err)
          this.errored = true
          $state.complete()
        })
        .finally(() => {
          this.loading = false
        })
 
        if (typeof videos === 'undefined') return
          if (videos.data.length > 0) {
            this.page += 1
            this.videos = videos.data
            this.loaded = true
            // $state.loaded()
          } else {
            $state.complete()
          }
    },*/

    async getPopularVideos($state) {
      if (!this.loaded) {
        this.loading = true
      }
      const popularvideos = await VideoService.getAll('public', { page : this.page})
        .catch((err) => {
          console.log(err)
          this.errored = true
          $state.complete()
        })
        .finally(() => {
          this.loading = false
        })


      if (typeof popularvideos === 'undefined') return

      if (popularvideos.data.data.length) {
        this.page += 1
        this.popularvideos.push(...popularvideos.data.data)
        // $state.loaded()
        this.loaded = true
      } else {
        $state.complete()
      }
    },

    async gettrendingVideos($state) {
      if (!this.loaded) {
        this.loading = true
      }

      const trendingvideos = await VideoService.getAll('public', {
        page: this.page,
        sort: '-views'
      })
        .catch((err) => {
          console.log(err)
          this.errored = true
        })
        .finally(() => {
          this.loading = false
        })

      if (typeof trendingvideos === 'undefined') return

      if (trendingvideos.data.data.length) {
        this.page += 1
        this.trendingvideos.push(...trendingvideos.data.data)
        $state.loaded()
        this.loaded = true
      } else {
        // $state.complete()
      }
    },
    dateFormatter(date) {
      return moment(date).fromNow()
    }
    
  },
    computed: {
 
  },
    async created()
    {
       this.gettrendingVideos()
       this.getVideos()
       this.getPopularVideos()

    },
  components: {
    VideoCard,
    InfiniteLoading,
    VueSlickCarousel
  }
}
</script>

<style lang="scss">

</style>
