<template>
   <div class="">
      <section class="common_following_page">
         <div class="container">
            <h1>Following Channels</h1>
         </div>
         <div class="learning_following_page">
            <div class="container">
              <div v-if="subscribeLength === false">
                No data available
              </div>
               <div v-else class="first_list">
                <VueSlickCarousel v-bind="settings">
                <div>
                    <div class="learning_following" v-for="items in subscribers" :key="items._id">
                        <div class="learning_following_image" >
                          <!-- {{items.channelId.photoUrl}} -->
                          <img v-if="items.channelId.photoUrl" v-bind:src="`${items.channelId.photoUrl}`" :alt="`${items.channelId.photoUrl} avatar`"> 
                          <img v-else v-bind:src="`${getUrl}/no_image.jpg`" :alt="`${items.channelId.photoUrl}`">
                          </div>
                        <div class="learning_following_content">
                           <h3> {{ items.channelId.channelName}}                  </h3>


                        <p> {{ subscribers.length }} Followers</p>
                           <a class="btn btn-primary" @click="followingPop(items.channelId._id,items.channelId.channelName)" href="#">FOLLOWING</a>


                        </div>
                     </div>
                </div>
                  
                 </VueSlickCarousel>
                 </div>
            </div>
             <div>
             </div>
         </div>
      </section>
          <modal name="show-voice-modal-pop"  ref="listing"
    :adaptive="true"
    width="100%"
    :maxWidth="457"
    height="auto"
    :scrollable="true"
    >
    <!-- @before-open="handleBeforeOpen" -->
        <div class="unfollow_popup_middle">
        <div class="unfollow_popup">
        <h2>{{ followchannelName }}</h2>
        <div class="login_buttons">
                  <div class="row">
                     <div class="col-lg-6 col-md-6 col-6">
                     <v-btn depressed @click="cancel">
                       Cancel
                       </v-btn>
 
                     </div>
                     <div class="col-lg-6 col-md-6 col-6">
                           <v-btn
                         depressed
                         @click="getfollowers"
                         color="primary">
                       UNFOLLOW
                       </v-btn>
                           <!-- <v-btn depressed color="primary" @click="unFollowing">
                         UNFOLLOW
                         </v-btn> -->
                     </div>
                  </div>
               </div>
        </div>
        </div>
    </modal>
       <div class="height"></div>



   </div>

</template>


<script>
import { mapGetters } from 'vuex'
import SubscriptionService from '@/services/SubscriptionService'
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import Vue from 'vue';
import vmodal from 'vue-js-modal';
Vue.use(vmodal);
 
// Tell Vue to install the plugin.
//Vue.use(VuejsDialog);

  export default {
    name: 'Following',
    components: { VueSlickCarousel },
     data () {
    return {
             subscribers: [],
             channelsubscribers:[],
             subscribeLength: true,
        followchannelName:"",
      
       subscriberId: '',
       deleteMessage: '',

        dialog: false,
         settings: {
        "dots": false,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 6,
        "slidesToScroll": 4,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 5,
              "slidesToScroll": 3,
              "infinite": true,
              "dots": false
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1
            }
          }
        ]
      },

    };
  },
  computed: {
    ...mapGetters(['currentUser', 'getUrl']),
  
  },
    methods: {
   followingPop(id,channelName){
     this.follow_id = id
     this.followchannelName = channelName
   
    this.$modal.show('show-voice-modal-pop')
    },
    cancel() {
 this.$modal.hide('show-voice-modal-pop')
    },
     subscribe(item) {
      console.log(item)
    },
    async getSubscribers() {
      this.loading = true
      const subscribers = await await SubscriptionService.getSubscribedChannels(
        this.currentUser._id
      ).catch((err) => console.log(err))
        .finally(() => {
          this.loading = false
        })

      if (!subscribers) return
      if(subscribers.data.data.length > 0){      

        this.subscribeLength = true
      }else{
        this.subscribeLength = false
      }
     
      this.subscribers = subscribers.data.data


    console.log(subscribers)
    },

      async getfollowers(){
    // alert(id)
      this.loading = true
      const sub = await SubscriptionService.createSubscription({
        channelId: this.follow_id
      })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
          this.$modal.hide('show-voice-modal-pop')
          this.getSubscribers()
        })
// console.log(sub)
      if (!sub) return

      if (!sub.data.data._id) this.subscribed = false
      else this.subscribed = true
  },
   
  },

  
   mounted () {
        this.getSubscribers()

  },
    
  }
</script>
<style>
.slick-slide img{
  display: inline-block;
}
</style>